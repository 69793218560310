import * as React from "react"
import Fade from "react-reveal/Fade"

const AboutHoliday = () => (
    <div>
        <div className="row mx-0">
            <Fade delay={400} duration={1500}>
                <div className="col-12 px-0 d-flex flex-column justify-content-center align-items-center">
                    <div className="the-ultimate-holiday-experience d-flex justify-content-center">
                        The Ultimate <br /> &nbsp;holiday experience
                    </div>
                    <div className="holiday-text-container">
                        <p className="text-purple text-center">
                            If you’re looking for the perfect day at sea, with good vibes and stunning views of Ibiza, Maliblue Yachts
                            has a unique fleet of seamless yachts available for rental for your special day, complete with package
                            deals that will pair together to give you the ultimate holiday experience. We will have something to offer
                            for all groups from food and beverage packages, to a backdrop of music and playlists to set the scene
                            throughout the day - <b>powered by Candypants!</b>
                        </p>
                        <p className="text-purple text-center">
                            Candypants’ presence in Ibiza began at STK Ibiza where our weekly residency took place every Saturday in a
                            vibrant brunch format, we are aiming to bring that same memorable energy to the Island in 2022, with
                            Maliblue Yachts being the next personalised experience for party goers in Ibiza.
                        </p>
                    </div>
                </div>
            </Fade>
        </div>
    </div>
)

export default AboutHoliday
