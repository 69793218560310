import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Fade from "react-reveal/Fade"

const AboutMaliblueCalling = () => (
    <div className="about-maliblue-container">
        <div className="row mx-0">
            <div className="col-12 px-0 position-relative">
                <StaticImage className="w-100" src="../../images/about-island.jpg" alt="about-island" />
                <Fade delay={400} duration={1500}>
                    <div className="about-maliblue-is-calling">
                        Maliblue <br /> &nbsp;is calling...
                    </div>
                </Fade>
            </div>
        </div>
    </div>
)

export default AboutMaliblueCalling
