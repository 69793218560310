import * as React from "react"
import AboutCandypantsOne from "../components/about/aboutCandypantsOne"
import AboutCandypantsTwo from "../components/about/aboutCandypantsTwo"
import AboutDiscover from "../components/about/aboutDiscover"
import AboutGirls from "../components/about/aboutGirls"
import AboutHoliday from "../components/about/aboutHolidays"
import AboutMaliblueCalling from "../components/about/aboutMaliblueCalling"
import AboutTop from "../components/about/aboutTop"
import { Footer } from "../components/footer"
import { NavBar } from "../components/header"
import Layout from "../components/layout"
import Newsletter from "../components/newsletter"
import Seo from "../components/seo"

const AboutUs = () => (
    <Layout>
        <Seo title="About" />
        <NavBar />
        <AboutTop />
        <div className="positon-relative">
            <AboutGirls />
            <AboutCandypantsOne />
        </div>
        <AboutCandypantsTwo />
        <AboutHoliday />
        <AboutMaliblueCalling />
        <AboutDiscover />
        <Newsletter />
        <Footer />
    </Layout>
)

export default AboutUs
