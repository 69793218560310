import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import CandyPantsIcon from "../../images/candypants-icon.svg"
import Fade from "react-reveal/Fade"
import Roll from "react-reveal/Roll"

const AboutCandypantsTwo = () => (
    <>
        <div className="bg-darkblue candypants-two position-relative">
            <div className="row mx-0">
                <div className="col-12 ">
                    <Roll left>
                        <StaticImage className="about-girls-frame-2" src="../../images/about-frame2.png" alt="about-frame" />
                    </Roll>
                    <div className="overflow-hidden">
                        <img src={CandyPantsIcon} className="about-cpi2" alt="candy-pants-icon" />
                    </div>
                    <Fade duration={1500} delay={700} cascade>
                        <div className="candypants-text-two">
                            <p className="text-white text-center text-md-start first">
                                After perfecting and growing our presence in Dubai, the UK, European territories, and throwing
                                unforgettable parties in the United States; Miami and Las Vegas, we continue our growth in the Balearic
                                Island of Ibiza.
                            </p>
                            <p className="text-white candypants-heading-two text-center mb-0 text-md-start second">
                                <b>Unparalleled</b> and <b>unique</b> yacht company, Maliblue Yachts have partnered up with Candypants.
                            </p>
                        </div>
                    </Fade>
                </div>
                <StaticImage className="d-md-none" src="../../images/about-party.png" alt="about-party" />
                <StaticImage className="d-none d-md-block" src="../../images/about-party-full.png" alt="about-party-full" />
            </div>
        </div>
        <img src={CandyPantsIcon} className="about-cpi3" alt="" />
        <Roll right>
            <StaticImage className="about-girls-frame-3" src="../../images/about-frame3.png" alt="about-frame" />
        </Roll>
        <img src={CandyPantsIcon} className="about-cpi4 d-none d-md-block" alt="candy-pants-icon" />
    </>
)

export default AboutCandypantsTwo
