import React, { useState } from "react"
import SubscriptionForm from "./forms/subscriptionForm"
import Fade from "react-reveal/Fade"

export const ArrowIcon = ({ fill, className }) => (
    <svg className={`${className ?? ""} arrow-news`} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            style={{ transition: "all .3s" }}
            d="M16 8L14.59 6.59L9 12.17L9 -3.0598e-07L7 -3.93402e-07L7 12.17L1.41 6.59L-3.49691e-07 8L8 16L16 8Z"
            fill={fill}
        />
    </svg>
)

const Newsletter = () => {
    const [ariaExpanded, setAriaExpanded] = useState(true)
    const [isHoveredToggle, setIsHoveredToggle] = useState(false)

    return (
        <div className="bg-lightblue">
            <div className="container-xxl">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <Fade duration={1500} delay={400}>
                            <h2 className="py-3 py-md-5">Get the latest from Maliblue Yachts, sign up to our newsletter</h2>
                        </Fade>
                    </div>
                    <div className="col-12 pb-3 col-md-6 d-flex align-items-center justify-content-md-end">
                        <button
                            className={`button d-flex align-items-center justify-content-center fill light-background button-open-news ${
                                ariaExpanded ? "collapsed" : ""
                            } button-news-expand`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#newsletter"
                            aria-expanded={ariaExpanded}
                            aria-controls="newsletter"
                            onClick={() => setAriaExpanded(!ariaExpanded)}
                            onMouseEnter={() => setIsHoveredToggle(true)}
                            onMouseLeave={() => setIsHoveredToggle(false)}
                        >
                            <ArrowIcon className={!ariaExpanded ? "open" : ""} fill={isHoveredToggle ? "#fff" : "#3D4998"} />
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="collapse" id="newsletter">
                            <div className="row">
                                <div className="col">
                                    <SubscriptionForm transparentBg />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Newsletter
