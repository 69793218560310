import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { BOOKING } from "../../utils/constants"
import Fade from "react-reveal/Fade"
import Roll from "react-reveal/Roll"

const AboutDiscover = () => (
    <div className="about-discover position-relative">
        <Roll left>
            <StaticImage className="about-girls-frame-4" src="../../images/about-frame4.png" alt="about-frame" />
        </Roll>
        <div className="row mx-3 ps-inside pe-inside">
            <div className="col-12 bg-purple py-3 d-flex flex-column justify-content-center align-items-center py-md-5">
                <Fade delay={400} duration={1500}>
                    <h2 className="text-white text-center pt-4 pb-3 discover-text">Discover the White Island with us!</h2>
                    <div className="pb-5">
                        <Link
                            className="button fill dark"
                            to={BOOKING ? "/book" : "/book-an-experience"}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <span>book an experience</span>
                        </Link>
                    </div>
                </Fade>
            </div>
        </div>
        <Roll right>
            <StaticImage className="about-girls-frame-5" src="../../images/about-frame5.png" alt="about-frame" />
        </Roll>
    </div>
)

export default AboutDiscover
