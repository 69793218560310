import * as React from "react"
import Fade from "react-reveal/Fade"
import CandyPantsIcon from "../../images/candypants-icon.svg"
import CandyPantsSide from "../../images/candypants-side.svg"

const AboutCandypantsOne = () => (
    <div className="bg-purple candypants-one">
        <Fade delay={500} duration={1500}>
            <div className="row mx-0">
                <div className="col-md-6 ps-md-0 d-none d-md-block d-flex flex-column align-items-center ">
                    <img src={CandyPantsSide} className="about-cpi-side" alt="Anchor vector" />
                    <p className="text-white candypants-heading-one text-center text-md-start">
                        Remixing the rules of global partying, Candypants are the beat behind some of the world’s wildest days and
                        nights.
                    </p>
                </div>
                <div className="col-12 position-relative overflow-hidden col-md-6 d-flex flex-column align-items-center">
                    <img src={CandyPantsIcon} className="about-cpi1" alt="Anchor vector" />
                    <p className="text-white candypants-heading text-center d-md-none">
                        Remixing the rules of global partying, Candypants are the beat behind some of the world’s wildest days and
                        nights.
                    </p>
                    <div className="candypants-text">
                        <p className="text-white text-center text-md-start">
                            As the brand continues to grow globally, our extension of expertise is branching out into different unique
                            hospitality sectors across the world.
                        </p>
                        <p className="text-white text-center text-md-start">
                            Candypants aim to keep pushing the boundaries of what is possible, with the end goal of becoming one of the
                            world's leading lifestyle party brands. Our various event concepts leave fun loving party goers with an
                            unrivalled party experience and memories to last a lifetime.
                        </p>
                    </div>
                </div>
            </div>
        </Fade>
    </div>
)

export default AboutCandypantsOne
