import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import AnchorTopBlue from "../../images/anchor-top-blue.svg"
import AnchorBottomBlue from "../../images/anchor-bottom-blue.svg"
import Fade from "react-reveal/Fade"
import Roll from "react-reveal/Roll"

const AboutGirls = () => (
    <div>
        <div className="row mx-0 position-relative">
            <div className="col-6 d-none d-md-block">
                <img src={AnchorTopBlue} className="about-anchor-top" alt="Anchor vector" />
                <img src={AnchorBottomBlue} className="about-anchor-bottom" alt="Anchor vector" />
                <Fade duration={1500} delay={700}>
                    <h2 className="text-center text-about  ">
                        Maliblue Yachts in <br /> partnership with <br /> Candypants.
                    </h2>
                </Fade>
            </div>
            <div className="col-12 pe-0 position-relative d-flex flex-column justify-content-center col-md-6 px-md-0">
                <Fade right>
                    <StaticImage className="about-girls" src="../../images/about-girls.png" alt="girls" />
                </Fade>
                <Roll delay={200} left>
                    <StaticImage className="about-girls-frame1" src="../../images/about-frame1.png" alt="about-frame" />
                </Roll>
            </div>
        </div>
    </div>
)

export default AboutGirls
