import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import LogoTop from "../../images/maliblueyachts-top.svg"
import Candypants from "../../images/candypants.svg"
import X from "../../images/x-about.svg"
import Fade from "react-reveal/Fade"

const AboutTop = () => (
    <div>
        <div className="row mx-0 position-relative">
            <div className="col-12 d-flex flex-column align-items-center top-container-about p-0">
                <StaticImage className="background-top-water d-md-none" src="../../images/top-water1.jpg" alt="top-water-bg" />
                <StaticImage className="background-top-water d-none d-md-block" src="../../images/top-water2.jpg" alt="water-bg" />
                <Fade delay={700} duration={1500}>
                    <img className="mby-about" src={LogoTop} alt="Maliblue Yachts" />
                    <img className="x-about" src={X} alt="Maliblue Yachts" />
                    <img className="candypants-about" src={Candypants} alt="Maliblue Yachts" />
                </Fade>
            </div>
        </div>
        <Fade delay={700} duration={1500}>
            <h2 className="text-center text-about mb-5 d-md-none">
                Maliblue Yachts in <br /> partnership with <br /> Candypants.
            </h2>
        </Fade>
    </div>
)

export default AboutTop
